$tabsBorderRadius: 5px;

ul.tabs-nav, .tabs-nav {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 35px 0;
  margin: 0 30px;

  .tabs-nav-item {
    display: flex;
    height: 50px;
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 30px;
    align-items: center;
    justify-content: center;
    color: #333333;
    border: 1px solid #cdcdcc;
    cursor: pointer;
    transition: 0.5s all ease;

    &:first-child {
      border-top-left-radius: $tabsBorderRadius;
      border-bottom-left-radius: $tabsBorderRadius;
    }

    &:last-child {
      border-top-right-radius: $tabsBorderRadius;
      border-bottom-right-radius: $tabsBorderRadius;
    }

    &.active {
      border: 1px solid #a4558d;
      color: #fff;
      background: #a4558d;
    }
  }
}

.tab-content {
  display: none;

  &.active {
    display: block;
  }
}
