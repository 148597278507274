.wrapper-video-page {
  .content {
    padding: 0;
  }

  .video-row {
    display: flex;

    .video-block {
      flex: 1;
      height: 360px;
      display: flex;
      align-items: stretch;
      background-color: #004778;
      transition: 0.2s all ease;
    }

    .column {
      flex: 0 0 360px;
      display: flex;
      flex-direction: column;
      height: 360px;
      transition: 0.2s all ease;

      &:hover {
        flex: 0 0 400px;
      }
    }

    a {
      display: flex;
      width: 100%;
      background-size: cover;
      background-position: center;
      border: none;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        transition: 0.2s opacity ease;
        z-index: 2;
      }

      .info {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 15px;
        left: 30px;
        color: #fff;
        font-weight: 300;
        z-index: 3;
      }

      .info-title {
        font-size: 18px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
      }

      .info-date {
        font-size: 12px;
        margin-bottom: 5px;
      }

      .play {
        position: relative;
        width: 50px;
        height: 30px;
        background: #fc5665;
        margin-bottom: 10px;
        display: flex;

        &:after {
          content: '';
          position: absolute;

          margin: auto;
          width: 0;
          height: 0;
          top: 8.5px;
          left: 20px;
          border-style: solid;
          border-width: 6.5px 0 6.5px 13px;
          border-color: transparent transparent transparent #ffffff;
          line-height: 0px;
        }
      }

      &:hover {
        &:after {
          opacity: 0;
        }
      }
    }

    &:nth-child(1), &:nth-child(2) {

    }

  }
}

@media all and (max-width: 550px) {
  .video-row {
    flex-direction: column;
  }
}