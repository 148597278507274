.prices-page {
  .info-row {
    + tr {
      td {
        //border: none !important;
      }
    }

    td {
      background: #b8caef;
      font-size: 21px;
      font-weight: 500;
      color: #fff;
      min-height: 50px;
      vertical-align: middle;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 50px;
    }
  }

  table {
    tr:not(.info-row) {
      &:hover {
        td {
          background: #e8eefa;
          border-color: #e8eefa;
        }

        + tr {
          td {
            border-color: #e8eefa;
          }
        }
      }

      td {
        border-top: 1px solid #d5d5d5;
      }

      &:first-child {
        td {
          border: none;
        }
      }
    }

    td {
      background: #fff;
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: #333;

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }
    }
  }

  @media all and (max-width: 720px) {
    .tabs-nav {
      padding: 0;
      display: flex;
      flex-direction: column;
      margin: 0 0 20px 0;

      li {
        text-align: center;
        font-size: 14px;
        border-radius: 0;
        padding: 5px 0;

        &:first-child {
          border-bottom-left-radius: 0;
          border-top-right-radius: 5px;
        }

        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 0;
        }
      }
    }

    .info-row {
      td {
        background: #b8caef;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        min-height: 50px;
        vertical-align: middle;
        padding-top: 10px;
        padding-bottom: 10px;
        height: 50px;
      }
    }

    table {
      td {
        font-size: 13px;
        font-weight: 400;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
}

.price-selector {
  white-space: nowrap;
}