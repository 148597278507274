@import "reset";
@import "normalize";
@import "variables";

a {
  text-decoration: none !important;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.33);

  &:hover {
    text-decoration: none !important;
    color: #fff;
    border-bottom-color: transparent;
  }

  &:active {
    text-decoration: none !important;
  }

  &:visited {
    text-decoration: none !important;
  }
}

b, strong {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

p {
  line-height: normal;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 16px;
  position: relative;
  //min-height: 700px;
}

html, body {
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
}

body, html,
.wrapper,
header,
footer {
  //min-width: 992px;
  font-weight: 400;
  line-height: normal;
}

body {
  .body-background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(#000, 0.7);
    transform: translate(150%, 0) skew(25deg);
    transition: 0.1s all linear;
    z-index: 100;

    @media all and (max-width: 960px) {
      transform: translate(150%, 0) skew(0deg);
    }
  }
}

img {
  max-width: 100%;
}

.aligncenter {
  margin: 0 auto;
}

.alignright {
  float: right;
}

.section {
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.content-blocks {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  overflow: auto;

  .content-block {
    flex: 1;
  }

  @media all and(max-width: 960px) {
    .footer-wrap {
      display: none;
    }
  }
}

.content-block-style {
  color: #000;
}

.wrapper .content {
  height: 100%;
}

.wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-attachment: fixed;

  &.wrapper-video-page {
    background: url(../img/bg-video.jpg) no-repeat center top fixed;
    background-size: cover;
  }

  &.wrapper-events-page {
    background: url(../img/bg-events.jpg) no-repeat center top fixed;
    background-size: cover;
  }
}

.page-background {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.background-video {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    background-size: cover;
  }
}

//ASIDE START
.aside-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 430px;
  background: rgba(#092041, 0.9);
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 15px;
  padding: 30px 25px 0 45px;
  transition: 0.2s all ease;
  transform: translate(600px, 0);
  overflow: hidden;
  z-index: 100;

  .scroll-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 30px;
    margin-top: 10px;
  }

  .menu-logo {
    display: block;

    img {
      width: 160px;
      margin-top: -8px;
    }
  }

  .complex {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;

    .complex-title {
      font-size: 24px;
      cursor: pointer;

      > span {
        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
      }

      &.active {
        span {
          border-bottom-color: transparent;
        }

        img.blob {
          transform: rotate(180deg);
        }
      }
    }
  }

  .complex-nav, #menu-mobile-aside-top {
    > li {
      font-size: 16px;
      margin-right: 20px;

      a {
        display: inline-block;
        color: #fff;
        border-bottom: 1px solid;
        text-decoration: none;
        transition: all .1s linear;

        &:hover {
          border-bottom-color: transparent !important;
        }
      }

      &:first-child {
        a {
          color: #a34573;
          border-bottom-color: rgba(163, 69, 115, 0.33);
        }
      }

      &:nth-child(2) {
        a {
          color: #a34550;
          border-bottom-color: rgba(163, 69, 115, 0.33);
        }
      }

      &:nth-child(3) {
        a {
          color: #f0705b;
          border-bottom-color: rgba(240, 112, 91, 0.33);
        }
      }

      &:nth-child(4) {
        a {
          color: #f49756;
          border-bottom-color: rgba(244, 151, 86, 0.33);
        }
      }

      &:nth-child(5) {
        a {
          color: darken(#f49756, 10%);
          border-bottom-color: rgba(darken(#f49756, 10%), 0.33);
        }
      }

      &:nth-child(6) {
        a {
          color: #039cd6;
          border-bottom-color: rgba(3, 156, 214, 0.33);
        }
      }

      &:nth-child(7) {
        a {
          color: darken(#039cd6, 5%);
          border-bottom-color: rgba(darken(#039cd6, 5%), 0.33);
        }
      }

    }

    @for $i from 1 through 5 {
      li:nth-child(#{$i}) {
        transition: 0.3s linear all (0.1 + ($i/30) + s);
      }
    }
  }

  .currency-changer-menu {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 25px 0;

    .currency-changer {
      margin: 0 0 0 10px;
    }

  }

  #menu-mobile-aside-top {
    display: none;
  }

  .menu-nav {
    padding-top: 15px;
    padding-bottom: 5px;

    > li {
      display: block;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 10px;
      position: relative;
      right: -50px;
      opacity: 0;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
        text-decoration: none;

        &:hover {

        }
      }

      @media all and (max-width: 960px) {
        right: 0;
        opacity: 1;
      }
    }

    @for $i from 1 through 8 {
      li:nth-child(#{$i}) {
        transition: 0.2s linear all (0.0 + ($i/30) + s);
      }
    }
  }

  .prices-link {
    margin: 25px 0;
  }

  .menu-file {
    margin: 45px 0;
    display: inline-block;
    width: 100%;

    .file {
      float: left;
      width: 100%;
      max-width: 208px;

      > img {
        width: 37px;
        height: 48px;
        float: left;
        margin-right: 10px;
      }

      > a {
        color: #fff;
        text-decoration: none;
        margin: 7px 0 1px;
        display: inline-block;
        padding-bottom: 3px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        transition: all .2s linear;
        &:hover {
          border-bottom-color: transparent;
        }
      }

      > i {
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        font-weight: 300;
        font-style: italic;
        display: block;
        margin-top: 3px;
      }
    }
  }

  .time-info {
    color: #b1b1b2;
    font-size: 14px;
    line-height: 20px;
    padding-right: 20px;
    margin-bottom: 20px;

    .time-info-title {
      color: #fff;
      font-size: 18px;
      margin: 0 0 15px 0;
      text-transform: uppercase;
      letter-spacing: 2px;

      span {
        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
      }
    }
  }

  .phones-info {
    padding-bottom: 20px;
    margin-top: -10px;
  }

  .phone {
    color: #00b9ff;
    display: inline-block;
    border-bottom: 1px dotted rgba(0, 185, 255, 0.33);
    padding-bottom: 3px;
    margin: 5px 0;
    text-decoration: none;
  }

  .action-button {
    width: 100%;
    font-size: 16px;
    font-weight: 400;

  }

  .mCSB_container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.news-company {
  max-width: 250px;
  width: 100%;
  display: inline-block;

  .news-company-title {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .news-company-form {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    position: relative;

    form {
    }

    input {
      border: 1px solid #888;
      font-size: 14px;
      color: #b8b8b8;
      padding-left: 15px;
      height: 38px;
      line-height: 20px;
      outline: none;
      padding-right: 24px;
      width: 100%;
    }

    button {
      position: absolute;
      display: block;
      width: 18px;
      height: 13px;
      top: 12px;
      right: 12px;
      border: none;
      background: url(../img/icon-svg/envelope.svg) no-repeat center;
      background-size: contain;
    }
  }

  .news-company-info {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}

body.aside-opened {
  &.des {
    .content-block {
      filter: url(#blur) url(#css_grayscale);
      transform: translate(-3%, 0);
    }
  }

  @media all and (max-width: 960px) {
    .body-background {
      transform: translate(0, 0) skew(0deg);
    }
  }

  header.top-header {
    &:after {
      right: 445px;
    }

    .news-string {
      display: none;
    }
  }

  .aside-menu {
    transform: translate(0, 0);
    filter: none;

    .menu-nav {
      li {
        opacity: 1;
        right: 0;
      }

      .landing-link {
        //color: #FFDD22;
        color: #FFFFB3;
        font-weight: 400;
        text-transform: uppercase;
      }

    }

    .complex-nav {
      li {
        opacity: 1;
        left: 0;
      }
    }
  }

  .btn-menu {
    span {
      position: relative;
      top: -1px;
    }

    i:first-child {
      transform: rotate(45deg);
      top: 8px;
    }

    .middle {
      transform: rotate(120deg);
      opacity: 0;
    }

    i:last-child {
      transform: rotate(-45deg);
      top: -6px;
    }
  }

  .hide-on-menu-opened {
    display: none;
  }
}

.aside-wrap {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.header-background {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */

  position: absolute;
  width: 100%;
  min-height: 85px;
  top: 0;
  z-index: 2;
}

//HEADER START
header.top-header {
  position: fixed;
  padding: 20px 25px 0 50px;
  top: 0;
  min-height: 0;
  width: 100%;
  transition: 1s ease all;
  z-index: 115;

  &.cover {
    top: -100px;
    transition: 1s ease all;

    + .header-background {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -30px;
    z-index: 1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 75%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 75%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */
  }

  .header-inner {
    display: flex;
    position: relative;
    z-index: 2;
    justify-content: space-between;
  }

  .header-left {
    flex: 1;
  }

  .nav-top {
    transition: all 1s ease-in;

    ul {
      display: flex;
      align-items: center;
      width: 100%;

      li {
        float: left;
        margin: 0 12px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          text-shadow: 0 0 10px #fff;

          ul {
            display: flex;
          }
        }

        a {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          transition: all .2s linear;
          letter-spacing: 0.3px;
          white-space: nowrap;

          &:hover {
            border-bottom-color: transparent;
          }
        }

        ul {
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          top: 15px;
          padding-top: 10px;
          display: none;

          li {
            margin: 5px 0;
            text-shadow: none;

            a {
              font-size: 13px;
            }
          }
        }

        &.head-logo {
          margin-right: 40px;
          margin-left: 0;

          a {
            border-bottom: none;
            display: block;
            //margin-top: 18px;

            img {
              max-width: 125px;
              //margin-bottom: -20px;
            }
          }
        }

        &.landing-link-wrap {
          padding-left: 50px;
        }
      }
    }

    .action-button {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, .33);
      margin: 0;
      padding: 8px 35px;

      &:hover {
        border: 1px solid rgba(255, 255, 255, .33);
        opacity: 0.5;
      }
    }
  }

  .news-string {
    flex: 1;
    overflow: hidden;
    margin-left: 50px;
    position: relative;

    .marquee-line {
      overflow: hidden;
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;

      a {
        margin: 0 20px;
        display: inline-block;
        position: relative;
        white-space: nowrap;
        font-size: 16px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #21a9ff;
          top: 5px;
          left: -15px;
        }
      }
    }

    .js-marquee {
      white-space: nowrap;
    }
  }

  .webcams {
    padding-top: 20px;
    position: absolute;
    left: 500px;
    right: 0;
    top: 5px;
    display: block;

    .header-list-item {
      font-size: 15px;
      font-weight: 400;
      padding-right: 30px;
      float: left;
      white-space: nowrap;

      a {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.33);
        position: relative;
      }

      span {
        font-weight: 300;
        margin-left: 5px;
      }
    }
  }
}

.header-right {
  flex: 0;
  //flex-basis: 440px;
  padding-left: 15px;
  height: 0;
}

.text-page-content {
  table {
    width: 100%;

    td {
      padding: 10px 5px;
      border: 1px solid #ccc;
    }
  }
}

.header-right-inner {
  display: flex;
  position: relative;
  z-index: 300;
  transition: all 1s ease-in;
  justify-content: flex-end;
  height: 0;

  .socials {
    float: left;
    margin-right: 48px;
    line-height: 72px;

    > li {
      float: left;
      font-size: 14px;
      margin-left: 9px;
      color: rgba(255, 255, 255, 0.6);

      &:first-child {
        margin-right: 3px;
        margin-left: 0;
        font-weight: 300;
      }

      > a {
        color: #fff;

        > i {
          color: #fff;
          font-size: 10px;

          &.icon-vkontakte {
            &:hover {
              color: #0094f7;
            }
          }

          &.icon-facebook {
            font-size: 12px;
            &:hover {
              color: #3D5B99;
            }
          }
        }
      }
    }
  }

  .languages {
    margin-right: 5px;
    margin-top: 18px;
    display: flex;
  }

  .language-chooser {
    display: flex;

    > li {
      margin-left: 5px;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      > a {
        color: rgba(255, 255, 255, 0.6);
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        transition: all .2s linear;

        &:hover {
          border-bottom-color: transparent;
        }

        &.active {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .btn-menu {
    width: 90px;
    height: 17px;
    padding: 0 0;
    margin: 18px 0 0;
    font-size: 17px;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;

    span {
      display: inline-block;

      &:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        margin-right: 5px;
        position: relative;
        top: -4px;
      }
    }

    i.icon-bar {
      width: 22px;
      height: 3px;
      background: #fff;
      display: block;
      position: relative;
      transition: 0.3s all ease;

      &.middle {
        margin: 4px 0;
      }
    }
  }
}

//HEADER END

//CONTENT-----------------------------------------------------------------------------------------------START
.content {
  position: relative;
  z-index: 7;
  padding: 0 27px 0;
  width: 100%;
  transition: 0.2s linear all;
}

//HOME PAGE-------- 1-st page
.home-page {
  padding-top: 120px;
  .home-page-title {
    text-shadow: 0 0 2px #000;

    font-size: 60px;
    margin: 8% 30px;
    font-weight: 100;
    p {
      font-size: 14px;
      margin: 33px 0 45px;
    }
  }

  .booking-btns {
    > a {
      width: 250px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      display: inline-block;
      color: #fff;
      background: #9bcd4a;
      border-radius: 20px;
      margin-right: 10px;
      text-decoration: none;
      font-weight: 500;
      transition: all .2s ease-in;
      text-shadow: none;

      &:first-child {
        background: #3fc6ab;
      }

      &:last-child {
        background: #c6553f;
      }

      &:hover {
        opacity: .9;
        color: #fff;
      }
    }
  }
}

//CONTENT----------------------------------------------------------------------------------------------------END

//slide
.thumbs {
  width: 530px;
  font-size: 14px;
}

.big-images {
  width: 500px;
  float: left;

  .item {
    width: 220px;
    margin: 0 auto;
  }
}

//FOOTER START
.wrapper-bookmark {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.bookmark {
  width: 100%;
  max-width: 490px;
  min-height: 140px;
  background: url(../img/bg-bookmark.png) no-repeat left top;
  background-size: cover;
  padding: 0 35px;
  margin-left: -20px;
  font-weight: 300;
  font-size: 14px;

  .bookmark-logo {
    float: left;
    line-height: 140px;
    min-width: 142px;
    text-align: center;

    .bookmark-page-name {
      font-size: 36px;
      font-family: Philosopher;
    }

    img {
      width: 100%;
      max-width: 182px;
    }
  }
  .bookmark-nav {
    float: left;
    margin: 7px 0 0 70px;

    > li {
      display: block;
      margin: 0 0 3px;

      &:last-child {
        margin: 0 0 0;
      }

      > a {
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        transition: all .2s linear;
        font-size: 16px;
        font-weight: 300;

        &:hover {
          border-bottom-color: transparent;
        }
      }

      &.active {
        > a {
          border-bottom-color: transparent;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.information {
  margin: 10px 25px 15px;
  display: inline-block;
  width: 100%;
  text-shadow: 1px 1px 1px #111;

  .file {
    float: left;
    width: 100%;
    max-width: 208px;
    > img {
      width: 37px;
      height: 48px;
      float: left;
      margin-right: 10px;
    }
    > a {
      color: #fff;
      font-weight: 300;
      font-size: 16px;
      text-decoration: none;
      margin: 7px 0 1px;
      display: inline-block;
      padding-bottom: 3px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      &:hover {
        border-bottom-color: transparent;
      }
    }
    > i {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      font-weight: 300;
      font-style: italic;
      display: block;
      margin-top: 3px;
    }
  }
  .tel {
    float: left;
    margin: 0 20px;
    > img {
      float: left;
      width: 39px;
      height: 39px;
      margin-right: 10px;
    }
    > a {
      margin: 10px 0;
      display: inline-block;
      color: #fff;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
      transition: all .2s linear;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}

.footer-wrap.content-block {
  flex: 0 0 340px;
  position: relative;

  /*&.homepage-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    flex-basis: 0;
    width: 440px;
  }*/

  .footer {
    width: 330px;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    text-shadow: 1px 1px 2px #222;
    z-index: 20;

    .share {

    }
  }

  .sub-footer {
    padding: 0 27px 10px 20px;
  }

  .footer-content {
    position: absolute;
    left: 0;
    bottom: 40px;
    z-index: 9;
  }

  .news-company {
    //padding: 0;
  }
}

.aside-bottom {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.aside-share, .share {
  a {
    border: none;
  }

  > ul {
    display: inline-block;
    margin-bottom: 35px;
    list-style: none;

    > li {
      float: left;
      font-size: 24px;
      font-weight: 300;
      margin: 0 5px;

      a {
        font-size: 17px;

        i {
          font-size: 14px;
          color: #fff;

          &.icon-twitter {
            &:hover {
              color: #039cd6;
            }
          }

          &.icon-vkontakte {
            font-size: 12px;
            &:hover {
              color: #0094f7;
            }
          }

          &.icon-facebook {
            font-size: 16px;
            &:hover {
              color: #3D5B99;
            }
          }

          &.icon-youtube {
            font-size: 18px;
            &:hover {
              color: #E62117;
            }
          }

          &.icon-tripadvisor {
            font-size: 18px;

            &:hover {
              color: #589442;
            }
          }

          &.icon-foursquare {
            &:hover {
              color: #2d5be3;
            }
          }

          &.icon-instagram {
            font-size: 18px;

            &:hover {
              color: #f56040;
            }
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  > p {
    font-size: 14px;

    a {
      color: #fff;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(#fff, 0.33);
    }
  }
}

.aside-share {
  p {
    font-size: 14px;

    a {
      color: #fff;
      text-transform: uppercase;
      border: none;
    }
  }
}

.navigation-pages {
  > li.active {
    > a {
      color: rgba(255, 255, 255, 0.5) !important;
      border-bottom-color: transparent !important;
      text-decoration: none !important;
    }
  }
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 25px;
  width: auto;
  max-width: 400px;
  margin: 0 auto;
}

.mfp-move-horizontal {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;

    transform: translateX(-50px);
  }

  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: translateX(0);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {

    .mfp-with-anim {
      transform: translateX(50px);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

.gallery-page, .news-page {
  .title {
    a {
      color: #fff;
      border-bottom: 1px solid lighten(#fff, 50%);
      transition: 0.2s all ease;
      display: inline-block;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.action-button {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background: #05ac6c;
  border: none;
  border-radius: 20px;
  margin: 10px 0;
  padding: 15px 20px;
  text-decoration: none;
  transition: all .2s ease-in;
  text-transform: uppercase;
  font-weight: 500;

  &.transparent {
    background: rgba(255, 255, 255, 0.5);
  }

  &.transparent-mobile {
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  &:hover {
    opacity: .8;
    color: #fff;
  }
}

.sidebar-container {
  aside {
    position: static;
    display: block;
    background: transparent;
    padding: 0;
    opacity: 1;
  }
}

.contact-page {
  line-height: normal;

  p {
    line-height: normal;
  }

  .general-text {
    font-size: 20px;
  }

  table {
    width: 100%;
    td {
      padding: 0 20px 0 0;
      vertical-align: middle;
    }
  }

  .about-page .about-content {
    padding-left: 8%;
    padding-right: 8%;
  }
}

.not-found-page {
  height: 100vh;
  background: dimgrey;
  padding-top: 150px;
  background: url(../img/bg-events.png);
  background-size: cover;

  .not-found-header {
    font-size: 46px;
  }
}

.popup {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);

  z-index: 9999;
  transform: translate(-200%, 0);
  transition: 0.2s all linear;

  &.open {
    position: fixed;
    transform: translate(0, 0);
  }

  .popup-content {
    padding: 10px 35px 10px 35px;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 20vw;
    min-height: 15vh;
    border-radius: 10px;

    background: rgba(87, 53, 120, 1);
    background: -moz-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(87, 53, 120, 1)), color-stop(49%, rgba(159, 70, 116, 1)), color-stop(100%, rgba(240, 150, 77, 1)));
    background: -webkit-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -o-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -ms-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: linear-gradient(to right, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#573578', endColorstr='#f0964d', GradientType=1);

    span {
      font-size: 28px;
      display: block;
      margin-bottom: 10px;
    }

    .text {
    }

    i.close-popup {
      font-size: 15px;
      position: absolute;
      display: block;
      color: #fff;
      font-style: normal;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.about-page .contacts-wrap.about-content {
  padding-left: 2%;
  padding-right: 2%;
}

.contacts-wrap {
  $generalColor: #fff;
  $secondColor: #a8528f;
  $colorGray: #d8d8d8;

  h1, h2, h3 {
    margin-top: 0;
    text-transform: uppercase;
  }

  table {
    td {
      vertical-align: top;
      padding-right: 10px;
      border: none;
    }

    h1, h2, h3 {
      font-size: 21px;
    }
  }

  form {
    padding-bottom: 15px;
    input, textarea {
      border-radius: 3px;
      border: 1px solid #ccc;
      width: 100%;
      position: relative;
      z-index: 1;
      color: #000;

      &:active, &:focus {
        border-color: $generalColor;
        z-index: 2;
      }
    }

    input[type="text"], input[type="email"] {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
    }

    input[type="email"] {
      padding-right: 50%;
    }

    input[name="subject"] {
      padding-right: calc(50% + 30px);
    }

    .no-bottom-radius {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    textarea {
      padding: 10px;
      top: -1px;
      resize: vertical;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .info-label {
      color: $secondColor;
      font-size: 12px;
      font-style: italic;
      position: relative;
      top: 10px;
    }

    .row {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .cs-select {
      position: absolute;
      right: 17px;
      top: 1px;
      width: 50%;
      z-index: 3;

      .cs-placeholder, li span {
        padding: 10px 10px;
        font-size: 12px;
        color: #000;
        text-transform: uppercase;
      }

      .cs-placeholder {
        height: 38px;
        line-height: 38px;
        padding: 0 30px 0 10px;
        border-left: 1px solid $colorGray;

        &:after {
          right: 10px;
        }
      }

      li {
        border-bottom: 1px solid $colorGray;
        &:last-child {
          border: none;
        }

        &:hover, &:active {
          span {
            color: $secondColor;
          }
        }
      }

      &.cs-active .cs-options {
        padding-bottom: 0;
        border: 1px solid #ccc;
        overflow: hidden;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    input[type="submit"] {
      background: $secondColor;
      color: #fff;
      padding: 10px;
      width: auto;
      border: none;
      font-size: 14px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.map-wrap {
  position: relative;
  height: 550px;
  overflow: hidden;

  .map {
    width: 100%;
    height: 880px;
    margin-top: -230px;
    position: relative;

    .overlay {
      position: relative;

      img {
        position: relative;
        z-index: 2;
      }

      .name {
        position: absolute;
        left: 0;
        background-color: white;
        border-radius: 35px;
        color: #000;
        padding-left: 45px;
        padding-right: 15px;
        font-size: 16px;
        font-weight: normal;
        line-height: 35px;
        min-width: 0;
        height: 40px;
        text-transform: uppercase;

        white-space: nowrap;
      }
    }
  }
}

.currency-changer {
  display: flex;
  margin: 16px 10px 0 0;

  li {
    //margin: 0 5px;

    &:after {
      content: '/';
      margin: 0 5px;
      position: relative;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    span {
      font-weight: bold;
    }
  }

  li, a, span {
    white-space: nowrap;
  }
}

@media all and(max-width: 1100px) {
  header.top-header {
    padding-left: 15px;
  }
}

//@import "video-controller";

//PAGES
@import "pages/home";
@import "pages/news";
@import "parts/tabs";
@import "parts/map";
@import "pages/restaurant";
@import "pages/about-pages";
@import "pages/gallery";
@import "pages/video";
@import "pages/webcam";
@import "pages/price";
@import "pages/beach&club";
@import "pages/party";

@import "helpers";
@import "queries";