/*==========  Desktop First Method  ==========*/

@media all and (max-width: 1170px) {
  body.aside-opened {
    .landing-link-wrap {
      display: none;
    }
  }

  .news-page .news-content {
    padding: 20px;
  }

  .currency-changer-header {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  body.aside-opened {
    header.top-header {
      .nav-top {
        ul {
          li {
            &.landing-link-wrap {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  header.top-header {
    .nav-top {
      ul {
        li {
          &.head-logo {
            padding-right: 0;
            margin-right: 0;
          }

          &.landing-link-wrap {
            padding-left: 0;
            //display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  header.top-header {
    .news-string {
      display: none;
    }
  }

  body.aside-opened {
    overflow: hidden;
  }

  .content-blocks {
    .content-block {
      width: 100%;
    }

  }

  .homepage .slider {
    min-height: 0;

    .slide-content .action-button a {
      display: block;
      padding: 10px;
      font-size: 16px;
      margin: 0;
    }
  }

  .aside-menu {
    .scroll-container {
      width: 95%;
      overflow: scroll;
    }

    #menu-mobile-aside-top {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }

    .aside-bottom {
      justify-content: flex-start;

      .aside-share, .share {
        padding-top: 10px;

        > ul {
          margin-bottom: 20px;

          > li {
            font-size: 18px;
          }
        }
      }
    }

    .time-info {
      .time-info-title {
        font-size: 15px;
      }
    }

    .languages {
      position: relative;
      top: -3px;
    }
  }

  .action-button {
    padding: 10px;
  }

  .slick-slide {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .slide-content {
      position: relative;
      z-index: 2;
    }
  }

  video {
    display: none;
  }

  //News
  .news-page {
    .single-page-image {
      background: #fff;
      border-bottom: 1px solid #ccc;

      img {
        filter: none;
        transform: none;
      }

      .center {
        position: static;
        padding: 15px 10px;
        transform: translate(0, 0);
        color: #000;
      }

      .news-item-title {
        font-size: 20px;
        letter-spacing: normal;
        color: #000;
      }

      .news-item-date {
        margin-top: 10px;
        font-size: 14px;
        display: block;
      }
    }

    .news-block-title {
      margin-bottom: 10px;
    }

    .news-block {
      .news-image {
        display: none;
      }
    }
  }

  .single-page-pagination {
    //flex-direction: column;
    height: auto;

    > div {
      height: 65px;

      &:last-child {
        //border-top: 1px solid #cfd6da;
        //border-left: none;
      }
    }
  }

  .recent-posts .news-block {
    width: 100%;
    flex: 1;
    padding: 0 10px;

    .news-image {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  //Gallery
  .gallery-page {
    padding-top: 100px;

    .gallery-album-block.gallery-block {
      position: static;
    }
  }

  .gallery-block {
    width: 100%;
    max-width: 100%;
    height: 200px;
    position: static !important;
    display: flex;
    justify-content: center;

    &.large {
      width: 100%;
      max-width: 100%;
      height: 200px;

      > a {
        width: 225px !important;
      }
    }

    > a {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .gallery-album-info {
    position: static;
    height: auto;
    padding-bottom: 15px;
  }

  .gallery-content {
    text-align: center;
  }

  .photos-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-page {
    .content {
      padding: 0;
    }

    .contacts-wrap {
      padding-top: 10px;
    }

    table {
      tr {
        display: flex;
        flex-direction: column;

        td {
          padding: 10px 0;
        }
      }
    }

    .form-wrap {
      padding-top: 15px;
    }
  }

  .restaurant-page {
    padding-top: 90px;
    margin-bottom: 0;

    .restaurant-content {
      .tabs-nav {
        margin: 10px 0;
        padding: 12px 0;

        li {
          width: 30%;

          a {
            font-size: 14px;
          }
        }
      }

      .general-categories {
        width: 100%;

        li {
          text-align: center;
          border-bottom: 1px solid #ccc;

          &.active {
            font-weight: bold;
          }
        }
      }
    }

  }
}

@media only screen and (max-width: 550px) {
  body {
    min-height: 0;
  }

  .homepage {
    .slider {
      height: 100%;

      .slide-content {
        padding: 10px;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        text-align: center;

        h1, h2, h3 {
          text-align: center;
          font-size: 30px;
        }
      }
    }
  }

  .footer-wrap {
    display: none;
  }

  header.top-header {
    width: 100%;
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    z-index: 300;

    .menu-second-menu-container {
      display: none;
    }

    .nav-top {
      ul {
        li {
          &.head-logo {
            padding-right: 0;
            margin-right: 0;
          }

          &.landing-link-wrap {
            padding-left: 0;
            display: none;
          }
        }
      }
    }

    .header-right-inner {
      .languages {
        position: absolute;
        right: 90px;
      }

      .btn-menu {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .aside-menu {
    padding: 70px 0 15px 15px;
    right: 0;
    left: 0;
    width: auto;

    .menu-logo {
      display: none;
    }
  }

  .single-page-bottom {
    padding-top: 0;
  }

  .single-page-pagination {
    > div {
      text-align: center;
      padding: 0 10px;

      &.prev-link-wrap {
        padding-right: 10px;

      }

      &.next-link-wrap {
        padding-left: 10px;
      }
    }
  }
}
