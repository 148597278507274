.wrapper-about-page {
  height: auto !important;
  overflow: hidden;

  .background-video {
    position: fixed;
  }

  .content {
    height: auto;
  }
}

.title-about-page {
  position: absolute;
  font-size: 48px;
  top: 330px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 0;
  font-weight: 300;
  text-transform: uppercase;
}

.about-page {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding-top: 170px;
  min-height: calc(100vh - 270px);

  p {
    margin-bottom: 15px;
  }

  table {
    margin-bottom: 20px;
  }

  .title {
    text-transform: uppercase;
    font-size: 48px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .about-content {
    background: $textPagesBackground;
    border-radius: 6px;
    color: $textPagesTextColor;
    padding: 50px 14%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;

    .head {
      text-transform: uppercase;
      font-size: 48px;
      margin-bottom: 20px;
    }

    h1, h2 {
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .text {
      font-size: 16px;
      //margin-top: 30px;
      line-height: 24px;

      p {
        margin-bottom: 15px;
      }
    }
  }
}

.page-photo-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -5px;

  .page-gallery-item {
    width: 25%;
    padding: 5px;

    img {
      margin: 0;
    }

    &:hover {
      img {
        transition: 0.2s all linear;
        filter: url(#css_grayscale);
      }
    }
  }
}

@media all and(max-width: 550px) {
  .wrapper-about-page {
    .content {
      padding: 0;
    }
  }

  .about-page {
    padding-top: 70px;

    .about-content {
      padding: 15px;
    }
  }
}