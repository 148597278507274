.webcam-page {
  video, .cam-player {
    width: 100%;
  }

  .cam-player {
    height: 60vh;
    max-height: 600px;
  }

  .cam-title {
    display: block;
    text-align: center;
    padding: 15px 0;
    font-size: 24px;
  }
}