.wrapper-party-page {
    height: auto !important;
    overflow: hidden;
    .background-video {
        position: fixed;
    }
    .content {height: auto;}
    
    .footer {
        position: relative;
        float: left;
        padding: 0 27px 0 35px;
    }
    .share {
        position: relative;
        right: 0;
        bottom: 0;
        float: right;
        margin-right: 70px;
    }
}

.title-party-page {
    position: absolute;
    font-size: 48px;
    top: 330px;
    left: 0;
    text-align: center;
    width: 100%;
    padding: 0 0;
    font-weight: 300;
    text-transform: uppercase;
//    @include filter (blur(0px));
}

.party-page {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 80px;
    padding-top: 170px;
    min-height: calc(100vh - 270px);
    .title {
        text-transform: uppercase;
        font-size: 48px;
        text-align: center;
        font-weight: 300;
        margin-bottom: 50px;
    }
    .party-content {
//        height: 2000px;
        background: #fff;
        color: #000;
        padding: 50px 14%;
        .head {
            text-transform: uppercase;
            font-size: 48px;
            margin-bottom: 20px;
        }
        .text {
            font-size: 16px;
            margin-top: 30px;
            line-height: 18px;
        }
    }
}