.wrapper-gallery-page {
  overflow: hidden;

  .background-video {
    position: fixed;
  }

  .content {
    height: auto;
  }
}

.title-gallery-page {
  position: absolute;
  font-size: 48px;
  top: 330px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 0;
  font-weight: 300;
  opacity: 1;
}

.gallery-page {
  width: 100%;
  max-width: 1070px;
  margin: 0 auto 20px;
  padding-top: 170px;
  min-height: calc(100vh - 270px);

  .title {
    text-transform: uppercase;
    font-size: 48px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .gallery-content {
    background: $textPagesBackground;
    color: #fff;
    padding: 50px;
  }

  .gallery-album-content {
    padding: 100px 60px;
    position: relative;
  }
}

.gallery-block {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 225px;

  > a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    > img {
      width: auto;
    }
  }

  .gallery-name {
    position: absolute;
    bottom: 30px;
    left: 20px;
    color: #fff;
    //background: #ffc400;
    background: rgba(87, 53, 120, 1);
    background: -moz-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(87, 53, 120, 1)), color-stop(49%, rgba(159, 70, 116, 1)), color-stop(100%, rgba(240, 150, 77, 1)));
    background: -webkit-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -o-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: -ms-linear-gradient(left, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    background: linear-gradient(to right, rgba(87, 53, 120, 1) 0%, rgba(159, 70, 116, 1) 49%, rgba(240, 150, 77, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#573578', endColorstr='#f0964d', GradientType=1);
    padding: 18px;

    .gallery-name-title {
      font-size: 18px;
      text-transform: uppercase;
    }

    .gallery-name-data {
      font-size: 14px;
      font-style: italic;
    }
  }

  &.large {
    height: 413px;
    width: 465px;
  }
  &.medium {
    height: 413px;
    width: 225px;
  }
  &.small {
    width: 225px;
    height: 200px;
  }
}

.gallery-album-info {
  color: #fff;
  display: block;
  width: 100%;
  height: 100px;
  position: absolute;
  top: 35px;
  left: 60px;
  z-index: 1;

  .name {
    color: $textPagesTextColor;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 3px;
  }

  .data {
    font-size: 14px;
    color: #ffc400;
  }
}

.gallery-album-block {
  overflow: hidden;
  margin-bottom: 10px;
  width: 220px;

  > a {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    transition: 0.2s all linear;
    border: none;

    > img {
      width: 100%;
    }
  }

  &.grayscale a {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);

    &:hover {
      filter: unset; //url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
      -webkit-filter: unset;
    }
  }

  &.medium {
    height: 329px;
    width: 220px;
  }

  &.small {
    width: 220px;
    height: 146px;
  }
}

.page-gallery-item {
  display: block;
  float: left;
  width: 33%;
  padding: 2px 4px;
  font-size: 0;

  img {
    height: auto;
    display: block;
  }

  &:last-child {
    clear: right;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
    }
  }
}

@media all and(max-width: 1200px) {
  .gallery-page {
    .gallery-content {
      padding: 15px;
    }
  }
}

@media all and(max-width: 960px) {
  .gallery-page {
    .gallery-content {
      padding: 10px;
    }
  }
}

@media all and(max-width: 850px) {
  .gallery-page {
    .gallery-content {
      padding: 10px;
    }
  }

  .restaurant-page {
    .restaurant-content {
      .gallery-album-block {
        width: auto;
      }

      .tabs-nav {
        margin: 25px 10px;

        li {
          a {
            padding: 10px 15px;
          }
        }
      }
    }

    .restaurant-menu {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0 10px;

      .menuItem {
        padding: 0;
      }

      .general-categories {
        flex-direction: column;
      }
    }


  }
}