.restaurant-page {
  padding-top: 130px;
  margin-bottom: 50px;

  img {
    max-width: 100%;
    height: auto;
  }

  .wp-caption {
    max-width: 100%;
  }

  .restaurant-content {
    //background: rgba(255, 255, 255, 0.9);
    //color: #000;
    background: $textPagesBackgroundColor;
    color: $textPagesTextColor;
    padding: 60px 0;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    max-width: 1370px;
    margin: 0 auto;

    .tabs-nav {
      text-align: center;
      font-size: 0;
      margin: 35px 0 55px 0;

      li {
        display: inline-block;

        a {
          font-size: 16px;
          text-transform: uppercase;
          display: block;
          border: 1px solid #cdcdcc;
          border-left: none;
          padding: 12px 25px;
          color: $textPagesTextColor;
          transition: 0.3s all linear;
        }

        &.active a {
          background: #a8528f;
          color: #fff;
        }

        &:first-child a {
          border-left: 1px solid #cdcdcc;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child a {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    .tab-content {
      &:first-child {
        padding: 0 85px 0 120px;

        @media all and (max-width: 1200px) {
          padding: 0 25px 0 20px;
        }

      }
    }

    .photos {
      padding: 0 30px;

      img {
        width: 100%;
      }
    }

    .gallery-album-block {
      width: 25%;
      display: block;
      float: left;
      padding: 5px 10px;
    }

    .menu-content {
      padding: 0 5px;
      font-size: 14px;

      table {
        td {
          padding: 5px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  .text {
    p {
      margin-bottom: 15px;
    }
  }
}

.restaurant-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;

  ul {
    list-style: none;
    padding: 0;

    li {
      &:hover {
      }
    }
  }

  .general-categories {
    display: flex;
    padding: 0;
    justify-content: space-around;

    li {
      padding: 10px;
      font-size: 20px;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid #000;
      }
    }
  }

  .subMenuContent {
    .menuItem {
      width: 200px;
      height: 240px;

      img {
        display: block;
      }
    ;

      p {
        margin-bottom: 10px;
      }
    }

    .menu-name {
      font-size: 21px;
      margin: 0 0 10px 0;
    }
  }

  .menu-content, .general-menu-items, .restaurant-menu-items {
    width: 100%;
  }

  .menuItem {
    padding: 15px;
    flex: 1;
    min-width: 250px;
    //width: 30%;

    table {
      td {
        &:nth-child(2), &:nth-child(3) {
          //white-space: nowrap;
        }
      }
    }
  }

  .sub-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: 5px 10px;
      font-size: 16px;
      font-weight: 400;
      color: #4d8cca;
      cursor: pointer;

      a {
        color: #4d8cca;
      }

      &:hover {
        background-color: #cedfeb;
        color: #000;
        border-radius: 10px;
      }
    }
  }

  table {
    td {
      border: none;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
  }
}

@media all and (max-width: 1250px) {
  .restaurant-page .restaurant-content {
    .tab-content {
      &:first-child {
        padding: 0 25px;
      }
    }

    .restaurant-menu {
      .menuItem {
        width: 50% !important;
      }
    }
  }
}

@media all and (max-width: 720px) {
  .restaurant-page .restaurant-content {
    .restaurant-menu {
      .menuItem {
        width: 100% !important;
      }
    }
  }
}