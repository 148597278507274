.wrapper-news-page {
  position: relative;

  &:after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(#000, 0.7);
    z-index: 5;
  }

  .background-video {
    position: fixed;
  }

  .widget-title {
    display: none;
  }

  .widget {
    padding: 15px 0;
  }
}

.title-news-page {
  position: absolute;
  font-size: 48px;
  top: 330px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 0 0;
  font-weight: 300;
}

.news-page {
  width: 100%;
  max-width: 1070px;
  margin: 0 auto 80px;
  padding-top: 170px;

  .title {
    text-transform: uppercase;
    font-size: 48px;
    text-align: center;
    font-weight: 300;
    margin-bottom: 50px;
  }

  .news-content {
    //background: #fff;
    //color: #000;
    background: $textPagesBackgroundColor;
    color: $textPagesTextColor;
    padding: 35px 135px 35px 135px;
  }

  .single-page-image {
    position: relative;
    background: #000;
    overflow: hidden;

    img {
      opacity: 0.8;
      filter: url(#blur2);
      display: block;
      transform: scale(1.1);
    }

    .center {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      text-align: center;
    }

    .news-item-title {
      font-size: 35px;
      letter-spacing: 0.5rem;
      padding: 0 15px;
      color: #fff;
      text-transform: uppercase;
      text-align: center;

      &.big {
        letter-spacing: 0.5rem;
      }
    }
  }

  .news-content-item {
    //padding: 60px 0 30px 240px; //old
    padding: 35px 135px;
  }

  .navigation {
    padding: 20px 0;

    a {
      color: $textPagesTextColor;
      border-color: rgba($textPagesTextColor, 0.33);
    }
  }
}

.recent-posts {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .news-block {
    padding: 0 10px;
    max-width: 280px;
    flex: 0 0 33%;
    border: none;
    flex-direction: column;

    .news-image {
      margin: 0;
      height: 175px;
      background-size: cover;
    }

    .news-information {
      width: 100%;
      padding: 5px 0 0 0;
      float: none;
    }

    .recent-post-date {
      font-size: 14px;
    }

    a {
      color: #000;
      font-size: 20px;

      &:hover {
        border-bottom: 1px solid rgba(#000, 0.33);
      }
    }

  }

  .news-data {
    font-size: 14px;
  }
}

//News in list
.news-item {
  max-width: 675px;
  width: 100%;

  .news-item-date {
    font-size: 14px;
    color: #9f4574;
    display: block;
    width: 100%;
    text-align: center;
  }

  > img {
    width: 100%;
  }

  .news-item-text {
    font-size: 16px;
    line-height: normal;
    margin: 20px 0;
    font-weight: 400;
    //color: #000;
    color: $textPagesTextColor;
  }
}

.news-block-title {
  margin-bottom: 30px;
  width: 100%;
  display: block;

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    color: $textPagesTextColor;
  }
}

.news-block {
  min-height: 120px;
  width: 100%;
  max-width: 910px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.33);
  margin-bottom: 20px;
  display: flex;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .news-image {
    display: block;
    margin-right: 20px;
  }

  img {
    height: auto;
  }

  .news-information {
    float: left;
    max-width: 680px;
    width: 60%;
    padding-bottom: 15px;

    h3 {
      font-size: 24px;
      margin-bottom: 15px;
      > a {
        text-decoration: none;
        color: #26a1ff;
        border-bottom: 1px solid rgba(38, 161, 255, 0.33);
        &:hover {
          border-bottom-color: transparent;
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .news-data {
    float: left;
    text-align: left;
    display: flex;
    align-items: center;
    max-width: 105px;
    margin-left: 20px;

    span {
      font-size: 14px;
      color: #9f4574;
    }
  }
}

.single-page-bottom {
  padding: 20px 0;
  background: #fff;

  .news-block-title {
    color: #000;
    padding: 20px;
  }
}

.single-page-pagination {
  height: 50px;
  border: 1px solid #cfd6da;
  margin-bottom: 20px;
  display: flex;

  > div {
    flex: 0 0 50%;
    display: flex;
    align-items: center;

    &.prev-link-wrap {
      padding-right: 35px;
      justify-content: flex-end;
    }

    &.next-link-wrap {
      border-left: 1px solid #cfd6da;
      padding-left: 35px;
    }
  }

  a {
    font-size: 16px;
    opacity: 0.5;
    color: #000;
    display: block;

    &:hover {
      opacity: 1;
    }
  }
}

@media all and(max-width: 550px) {
  .news-page {
    padding-top: 70px;

    .news-content {
      padding: 10px;
    }
  }

  .news-block {
    flex-direction: column;

    .news-information {
      width: 100%;
      order: 2;
    }

    .news-data {
      line-height: normal;
      padding: 0 0 10px 0;
      margin: 0;
      order: 1;
    }

  }

  .recent-posts {
    flex-direction: column;
    align-items: center;
  }

}