#map-wrapper {
  position: relative;
  //height: 100%;

  #map {
    img {
      display: block;
    }
  }

  .map-legend {
    min-height: 140px;
    background: rgba(22, 45, 72, 0.8);
    padding: 7px 20px;
    display: flex;
    flex-flow: row wrap;

    .item {
      width: 20%;

      @media all and (max-width: 1400px) {
        width: 25%;
      }

      @media all and (max-width: 1200px) {
        width: 33%;
      }

    }

    .info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 7px 0;
      //line-height: 30px;
      height: 30px;
      font-size: 14px;
      padding-left: 35px;
      padding-right: 10px;
      position: relative;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
    }

    @media all and (max-width: 720px) {
      padding: 5px 10px;

      .item {
        width: 50%;
      }
    }
  }

  svg {
    width: 100%;
    height: 500px;
  }

  .controls {
    position: absolute;
    right: -60px;
    //top: calc(50% - 60px);
    top: 0;

    button {
      display: block;
      background: rgba(0, 0, 0, .33);
      text-align: center;
      color: #fff;
      font-size: 25px;
      border: none;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 2px;
      transition: 0.3s all ease;

      &:active, &:focus {
        outline: none;
      }

      &:first-child {
        font-size: 30px;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, .5);
      }
    }
  }
}

.map-tabs {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;

  ul {
    display: table;
    height: 40px;
    width: 100%;

    li {
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      display: table-cell;
      border-right: 1px solid rgba(255, 255, 255, 0.33);
      line-height: 40px;
      height: 40px;
      opacity: 0.5;

      &:last-child {
        border: none;
      }

      &:hover, &.active {
        opacity: 1;
      }

      &.active {
        span {
          &:before {
            content: "";
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #21a9ff;
            display: block;
            position: absolute;
            top: 5px;
            left: -15px;
            margin-top: -3px;
          }
        }
      }

      span {
        font-size: 14px;
        cursor: pointer;
        position: relative;
      }

    }
  }
}