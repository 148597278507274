.wrapper-beach-page {
  background: url(../img/bg-events.jpg) no-repeat center top;
  background-size: cover;
  background-attachment: fixed;
}

.wrapper-club-page {
  background: #000;
}

.beach-page {
  padding-bottom: 300px;

  @media all and (max-width: 720px) {
    padding-bottom: 50px;
  }
}

.beach-page,
.club-page {
  padding-top: 100px;
  overflow: hidden;
  position: relative;
  z-index: 22;
  //min-height: 1100px;

  h1, h2, h3 {
    font-size: 18px;
    color: #fff;
    margin: 5px 0;
    line-height: normal;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .gallery-header {
    padding-left: 80px;
    position: relative;
    font-size: 16px;
    height: 36px;
    line-height: 36px;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 50px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-weight: 300;
      text-align: center;
    }

    &:before {
      top: 4px;
      left: 13px;
      z-index: 1;
      height: 30px;
      background: transparent;
      border-left: 2px solid #ff5665;
      border-bottom: 2px solid #ff5665;
    }

    &:after {
      content: attr(data-count);
      position: absolute;
      left: 17px;
      background: #ff5665;
      top: 0;
      z-index: 2;
    }
  }

  .left-block {
    max-width: 600px;
    min-width: 500px;
    flex: 0 0 40%;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    margin-right: 3%;
    order: 2;

    h2 {
      margin-bottom: 20px;
    }

    @media all and (max-width: 720px) {
      min-width: 0;
      max-width: none;
      flex: 1;
      width: 100%;
    }
  }

  .right-block {
    flex: 1;
    order: 1;
    max-width: 830px;
    position: relative;

    .right-block-head {
      margin-bottom: 50px;
      font-size: 18px;
      color: #fff;
    }

    .block-text {
      font-size: 14px;
      line-height: 18px;
      opacity: .8;
    }

    .block {
      width: 100%;
      display: block;
      margin-top: 40px;

      img {
        width: 100%;
        display: block;
      }
    }

    .block-bottom {
      > a {
        display: inline-block;
        text-decoration: none;
      }
    }

    .numbers {
      float: left;
      width: 230px;
      text-align: left;
      background: #2e3039;
      padding: 18px 0 18px 25px;
      height: 110px;

      ul {
        font-size: 16px;
        li:first-child {
          margin-bottom: 10px;
        }
        li:not(:first-child) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }
    }

    .btns {
      float: left;
      height: 110px;
      width: calc(100% - 230px);
      padding: 30px 30px 30px 0;
      background: #353741;
      text-align: center;

      a {
        height: 40px;
        float: right;
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        display: inline-block;
        color: #fff;
        border: 1px solid #fff;
        padding: 0 40px;
        background: transparent;
        border-radius: 20px;
        margin-right: 30px;
        text-decoration: none;

        transition: all .2s ease-in;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          opacity: .9;
        }
      }
    }

    .mobile-events-title {
      text-align: center;
      margin: 25px 0 15px;
      font-size: 24px;
    }

    @media all and (max-width: 720px) {
      .right-block-head {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  .tabs {
    .tabs-nav {
      display: flex;
      margin: 15px 0;
      width: 100%;
      padding: 0;

      li {
        flex: 1;
        text-transform: uppercase;
        padding: 15px 20px;
        text-align: center;
        cursor: pointer;
        border: 1px solid rgba(255,255,255,0.75);
        border-right: none;
        transition: 0.2s all ease;
        position: relative;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-right: 1px solid rgba(255,255,255,0.75);
        }

        &:hover {
          &:after {
            background: rgba(0, 174, 239, 0.2);
          }
        }

        &.active {
          &:after {
            background: rgba(0, 174, 239, 0.5);
          }
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          transition: 0.2s all linear;
        }
      }

      @media all and (max-width: 720px) {
        flex-direction: column;

        li {
          border-radius: 0;
          border: 1px solid rgba(255,255,255,0.75);
          border-bottom: none;

          &:first-child {
            border-radius: 0;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }

          &:last-child {
            border-radius: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom: 1px solid rgba(255,255,255,0.75);
          }
        }
      }
    }

    .tabs-content {
      .tab-content {
        visibility: hidden;
        position: absolute;
        z-index: -1;
        opacity: 0;
        transform: translate(0, -10px);

        &.active {
          visibility: visible;
          position: static;
          transition: 0.7s all linear;
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }

  .booking-btns {
    a {
      width: 250px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      display: inline-block;
      color: #fff;
      background: #9bcd4a;
      border-radius: 20px;
      margin-right: 10px;
      text-decoration: none;
      font-weight: 500;
      transition: all .2s ease-in;

      /*&:first-child {
        background: #3fc6ab;
      }

      &:last-child {
        background: #c6553f;
      }*/

      &:hover {
        opacity: .8;
        color: #fff;
      }
    }

    @media all and (max-width: 720px) {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 15px;

      a {
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

.beach-page {
  .left-block {
    .sub-footer {
      width: 100%;
      padding: 0;

      .bookmark {
        margin: 0;
      }
    }
  }

  .right-block {
    width: 100%;
    max-width: 100%;
    padding-bottom: 0;
  }

  @media all and (max-width: 720px) {
    .left-block {
      order: 1;
    }

    .right-block {
      order: 2;
    }

  }
}

.club-page {
  .left-block {
    margin-right: 8%;
  }
}

.club-page-blocks, .beach-page-blocks {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: stretch;
  margin-bottom: 10px;
}

.afisha-page {
  padding-top: 120px;
  height: 100%;
  position: relative;

  .afisha-item {
    img {
      max-width: 335px;
    }

    @media all and (max-width: 720px) {
      padding: 10px;
      border: 1px solid rgba(255,255,255,0.1);
      background: rgba(255,255,255, 0.1);
      margin-bottom: 20px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .afisha-event {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 0;
    display: none;
    transition: 0.4s all linear;
    transform: scale(1.02);

    .changed-background {
      position: absolute;
      background-size: 110%;
      background-position: center;
      top: -120px;
      bottom: 0;
      left: -30px;
      right: -30px;
      z-index: -1;
    }

    &.visible-event {
      display: block;
      position: relative;
      transform: scale(1);
      opacity: 1;
    }

    .afisha-img {
      margin-right: 30px;

      img {
        width: 100%;
        max-width: 335px;
        height: 460px;
        opacity: 0;
      }
    }
  }

  .afisha-information {
    width: 100%;
    max-width: 340px;
    margin-top: 25px;

    > h1 {
      font-size: 36px;
    }

    .afisha-time {
      margin: 5px 0;
      display: inline-block;
      width: 100%;
      font-size: 18px;

      .month {
        float: left;
        margin-right: 30px;
      }

      .hour {
        float: left;
      }

      i {
        margin-right: 5px;
      }
    }

    .event-text {
      font-size: 14px;
      line-height: 18px;

      .phones {
        margin-top: 15px;
        font-size: 16px;
        line-height: 1.5;
      }
    }

    a.take-ticket {
      background: #9bcd4a;
      color: #fff;
      height: 50px;
      width: 330px;
      display: block;
      text-align: center;
      line-height: 50px;
      text-transform: uppercase;
      border-radius: 5px;
      text-decoration: none;
      font-size: 18px;

      @media all and (max-width: 720px) {
        width: 100%;
      }

      &:hover {
        opacity: .9;
      }
    }

    @media all and (max-width: 720px) {
      margin-top: 10px;
    }
  }

  .price {
    font-size: 18px;
    margin: 10px 0 0;
    width: 100%;
    display: inline-block;
  }

  @media all and (max-width: 720px) {
    padding-top: 85px;

    h1, h2, h3 {
      margin-bottom: 15px;
    }
  }
}

.events-sub-footer {
  margin-top: 10px;
  width: 100%;
  padding: 0;

  .wrapper-bookmark {
    margin-bottom: 0;
  }

  .bookmark {
    width: 100%;
    margin: 0;
  }

  .bookmark-logo {
    float: none;
    text-align: left;
  }

  @media all and (max-width: 720px) {
    display: none;
  }
}

.events-carousel {
  width: 555px;
  position: absolute;
  //left: 475px;
  top: 0;
  left: -105px;
  // bottom: 0;

  .owl-stage {
    left: -115px;
  }

  .out-prev {
    opacity: 0;
  }

  .owl-item {
    transition: 0.5s all ease;
    //width: 200px !important;
    //margin-left: -100px;
    transform: scale(0.5);
    overflow: hidden;
    z-index: 0;
  }

  .active {
    opacity: 0;
  }

  .big {
    transform: scale(1);
    opacity: 1;
    z-index: 5;
  }

  .medium {
    transform: scale(0.9);
    opacity: 1;
    z-index: 4;
  }

  .small {
    transform: scale(0.8);
    opacity: 1;
    z-index: 3;
  }

  .extra-small {
    transform: scale(0.7);
    opacity: 1;
    z-index: 2;
  }

  .inner {
    overflow: hidden;
    width: 700px;
  }

  #carousel {
    margin-left: -10px;
  }

}

#carousel {
  width: 100%;
  height: 500px;
  position: absolute;
  clear: both;
  overflow: hidden;
}

#carousel img {
  visibility: hidden;
  cursor: pointer;
}

.event-background {
  position: absolute !important;
  left: -100px;
  right: -100px;
  top: -100px;
  bottom: -100px;
  z-index: 1;
  background-size: cover;
  //transition: 0.2s linear opacity, 0.3s linear transform 0.2s;
  opacity: 1;

  filter: url(#blur);
  -webkit-filter: blur(5px);
  filter: blur(5px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='5');

  //transform: scale(1.1);

  &.loading {
    transform: scale(0.98);
    opacity: 0;
  }
}

.event-background-base {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
}

.beach-gallery-head, .club-gallery-head {
  font-weight: 400;
  font-size: 21px;
  color: #fff;
  text-transform: uppercase;
  margin: 20px 0;
}

.beach-gallery,
.club-gallery {
  opacity: 0;

  .item {
    width: 118px;
    display: block;
    margin-bottom: 0;
    transform: scale(1.05) translate(-10px, 0);

    opacity: 0;

    &:first-child {
      width: 243px;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  @for $i from 1 through 8 {
    .item:nth-child(#{$i}) {
      transition: 0.2s linear all (0.5+($i/10)+s);
    }
  }

  &.loaded {
    opacity: 1;

    .item {
      opacity: 1;
      transform: scale(1) translate(0, 0);
      margin-left: 0;
    }
  }
}


// Add mediaqueri NAZAR
@media only screen and (max-width: 738px) {
  .club-page-blocks {
    //display: block !important;
    flex-direction: column;

    .left-block {
      order: 2;
    }

    .right-block {
      order: 1;
    }
  }

  .beach-page-blocks {
    flex-direction: column;
  }

  //.left-block {
  //  float: right !important;
  //  max-width: 100% !important;
  //  min-width: 100% !important;
  //  width: 100% !important;
  //  margin: 0 0 !important;
  //}
  //.right-block {
  //  float: left !important;
  //  width: 100% !important;
  //  max-width: 100% !important;
  //  min-width: 100% !important;
  //  padding-bottom: 50px !important;
  //}
}

.mobile-small-gallery {
  .gallery-album-block {
    width: 100%;
    margin: 0;
  }
}