.homepage {
  background: #233c4a;
  transition: 0.2s all linear;
  flex: 1;

  .slick-dotted.slick-slider {
    margin: 0;
  }

  .slider {
    height: 100vh;
    width: 100vw;
    //min-height: 700px;
    overflow: hidden;
    position: relative;

    .slick-slide {
      background-size: cover;
    }

    .video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }

    video {
      min-width: 100%;
    }

    .slick-slide {
      width: 100%;
    }

    .slide-content {
      padding: 70px 450px 10px 50px;
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 5;

      h1, h2, h3 {
        font-size: 60px;
        margin: 0;
      }

      .action-button a {
        margin: 15px 15px 0 0;
        font-weight: 300;
        letter-spacing: 1.5px;
        padding: 10px 40px;
        border: none;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 50px;
    z-index: 2;

    button {
      width: 16px;
      //height: 2px;

      height: 16px;
      border-radius: 50%;
      padding: 0;
      background: rgba(#fff, 0.5);

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        left: 0;
        right: 100%;
        top: 0;
        background: #fff;
        opacity: 0;
        transition: 0.2s all linear;
      }
    }

    .slick-active {
      button {
        &:before {
          opacity: 0.8;
          right: 0;
        }
      }
    }
  }
}